<template>
  <!-- 登录背景 -->
  <div
    class="login-back"
    :style="{
      background:
        'url(' + require('@/assets/images/testlogin_back.gif') + ')' + 'no-repeat',
    }"
  >
    <!-- 登录logo -->
    <!-- <img class="logo" src="@/assets/images/login-logo.png" alt="" /> -->
    <img class="logo_zhineng" src="@/assets/images/zhineng_logo.png" alt="" />
    <!-- 标题 -->
    <div class="box_outer">
      <div class="title">智能化多媒体管理和生产系统</div>
      <!-- 重置密码 -->
      <div class="forget1" >
        <!-- 找回密码标题 -->
        <div class="forget1-title">重置密码</div>
        <div class="text">已经将验证邮箱发送至邮箱</div>
        <div class="text1">{{ email}}</div>
        <div class="text2">点击邮件链接即可进行密码重置</div>
        <el-button type="primary" @click="loginEmail">登录邮箱</el-button>
        <div
          v-if="showlog == 0"
          style="cursor: pointer"
          class="reSend"
          @click="reSendEmail"
        >
          重新发送验证邮件
        </div>
        <div v-if="showlog == 1" class="reSend">{{ time }}s后重新发送邮件</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 倒计时时间
      time: 60,
      // 0展示重新发送验证邮件，1倒计时重新发送邮件
      showlog: 0,
      email:sessionStorage.getItem('Email') ,
    };
  },

  methods: {
    // 登陆邮箱
    loginEmail() {
      this.email.indexOf('maixunbytes.com')>-1?window.open("https://qiye.163.com/login/", "_blank"):window.open("https://login.live.com/", "_blank")
      //  跳转邮箱登录页面
    },
    // 重新发送验证邮件
    reSendEmail() {
      // 发送验证邮件请求
      let formData = new FormData();
      formData.append("email", sessionStorage.getItem('Email'),);
      this.$http({
        method: "POST",
        url: "accounts/retrieve_password_email",
        data: formData,
      }).then((res) => {
      }).catch(error => console.log(error));
      // 展示倒计时60s
      this.countdown();
    },
    // 倒计时函数
    countdown() {
      this.showlog = 1;
      this.time = 60;
      let timer = setInterval(() => {
        this.time--;
        if (this.time == 0) {
          this.showlog = 0;
          clearInterval(timer);
        }
      }, 1000);
    },
  },
  created(){
     if(this.$route.params.email!=undefined){
       // 展示倒计时60s
      this.countdown();
     }
  }
};
</script>

<style lang="scss" scoped>
//找回密码背景
.login-back {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  // 登录背景logo
  .logo {
     width: 11.5vw;
    height: 10.2vh;
    margin-top: 12vh;
    margin-right: 20.5vw;
  }
  .logo_zhineng{
    width: 13vw;
    margin: 12vh 19.5vw 1vh auto;
  }
  .box_outer{
    margin-right: 12.49vw;
  }
  // 标题
  .title {
    font-size: 1.6vw;
    letter-spacing: 4px;
    text-align: center;
  }
  // 重置密码容器
  .forget1 {
    margin-top: 6.8vh;
    width: 27.7vw;
    height: 47.6vh;
    background: rgba(57, 56, 70, 0.6);
    border-radius: 5px;
    .forget1-title {
      margin-left: 6.57vw;
      padding-top: 11.9vh;
      font-size:16px;
    }
    .text {
      margin-left: 6.57vw;
      margin-top: 2.9vh;
      font-size: 14px;
    }
    .text1 {
      margin-top: 1px;
      margin-left: 6.57vw;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
    }
    .text2 {
      margin-top: 1.6vh;
      margin-left: 6.57vw;
      font-size: 14px;
    }
    .el-button--primary {
     width: 16.8vw;
     margin-left: 6.57vw;
     margin-top: 3vh;
    }
    .reSend {
      display: flex;
      justify-content: flex-end;
      width: 16.8vw;
      margin-top: 17px;
      margin-left: 6.57vw;
      font-size: 12px;
    }
  }
}
</style>
