var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-back",
      style: {
        background:
          "url(" +
          require("@/assets/images/testlogin_back.gif") +
          ")" +
          "no-repeat"
      }
    },
    [
      _c("img", {
        staticClass: "logo_zhineng",
        attrs: { src: require("@/assets/images/zhineng_logo.png"), alt: "" }
      }),
      _c("div", { staticClass: "box_outer" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("智能化多媒体管理和生产系统")
        ]),
        _c(
          "div",
          { staticClass: "forget1" },
          [
            _c("div", { staticClass: "forget1-title" }, [_vm._v("重置密码")]),
            _c("div", { staticClass: "text" }, [
              _vm._v("已经将验证邮箱发送至邮箱")
            ]),
            _c("div", { staticClass: "text1" }, [_vm._v(_vm._s(_vm.email))]),
            _c("div", { staticClass: "text2" }, [
              _vm._v("点击邮件链接即可进行密码重置")
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.loginEmail } },
              [_vm._v("登录邮箱")]
            ),
            _vm.showlog == 0
              ? _c(
                  "div",
                  {
                    staticClass: "reSend",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.reSendEmail }
                  },
                  [_vm._v(" 重新发送验证邮件 ")]
                )
              : _vm._e(),
            _vm.showlog == 1
              ? _c("div", { staticClass: "reSend" }, [
                  _vm._v(_vm._s(_vm.time) + "s后重新发送邮件")
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }